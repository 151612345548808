var taskTable;
var allTasksTable;
var oTable;
var customTable


$(document).ready(function () {

    const language = {
        "sProcessing": "Bezig...",
        "sLengthMenu": "_MENU_ resultaten weergeven",
        "sZeroRecords": "Geen resultaten gevonden",
        "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
        "sInfoEmpty": "Geen resultaten om weer te geven",
        "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
        "sInfoPostFix": "",
        "sSearch": "Zoeken:",
        "sEmptyTable": "Geen resultaten aanwezig in de tabel",
        "sInfoThousands": ".",
        "sLoadingRecords": "Een moment geduld aub - bezig met laden...",
        "oPaginate": {
            "sFirst": "Eerste",
            "sLast": "Laatste",
            "sNext": "Volgende",
            "sPrevious": "Vorige"
        },
        "oAria": {
            "sSortAscending": ": activeer om kolom oplopend te sorteren",
            "sSortDescending": ": activeer om kolom aflopend te sorteren"
        },
        searchPanes: {
            emptyPanes: 'Geen zoekpaneel beschikbaar',
            emptyMessage: 'Geen data',
            loadMessage: 'Zoekpaneel laden...',
            showMessage: 'Toon zoekopties',
            collapseMessage: 'Verberg zoekopties',
            clearMessage: 'Verwijder alle filters',
            collapse: {0: 'Search Options', _: 'Zoekopties (%d)'},
            title: {
                _: 'Filters geselecteerd - %d',
                0: 'Geen filters geselecteerd',
                1: '1 filter geselecteerd'
            }
        },
    };
    customTable = $('.datatable-custom').DataTable({
        language: language,
    });
    oTable = $('#example-datatable').DataTable({
        dom: 'PBlfrtip',
        buttons: [
            {
                extend: 'copy',
                text: '<i class="fa fa-files-o" aria-hidden="true"></i>',
                titleAttr: 'Kopieer naar klembord'
            },
        ],
        searchPanes: {
            orderable: false,
            layout: 'columns-4',
            initCollapsed: true
        },
        columnDefs: [
            {
                searchPanes: {
                    show: false,

                },
                targets: [6, 7, 8, 11],
            },
            {visible: false, targets: 0},
            {orderable: false, targets: 1}
        ],
        language: language,
    });

})
